<template>
    <v-container class="live-events full-height full-width d-flex flex-column align-center justify-center">
        <template v-if="loading">
            <div class="full-height full-width d-flex align-center justify-center">
                <v-progress-circular :width="3" color="primary" indeterminate></v-progress-circular>
            </div>
        </template>

        <template v-else>
            <template v-if="room && room._id && !isScribe">
                <template v-if="isJudge">
                    <h2 class="text-center mb-6">
                        You have been selected as a judge for the <br />
                        <span class="primary--text">{{ competition.title }}</span> competition!
                    </h2>

                    <p class="text-center mb-0">
                        We are just waiting for the scribe to select an entry to judge. <br />
                        Please wait a moment.
                    </p>
                </template>

                <template v-else>
                    <h2 class="text-center mb-6">
                        You are now in the waiting room for <br />
                        <span class="primary--text">{{ competition.title }}</span>
                    </h2>

                    <p class="text-center mb-0">
                        If you are selected as judge, you will be able to move to the next stage. <br />
                        Please wait while the next artwork is selected.
                    </p>
                </template>

                <loading-spinner></loading-spinner>

                <v-btn :to="{ name: 'live-competitions' }" small text color="dark">
                    <span class="text-capitalize font-weight-regular"><b>Exit</b></span>
                </v-btn>
            </template>
        </template>

        <v-snackbar v-model="snackbar" timeout="6000" :color="snackbarColor" top>
            <span class="full-width text-center d-block">{{ snackbarText }}</span>
        </v-snackbar>
    </v-container>
</template>

<script>
import Room from "@/modules/app-module/room";
import Entry from "@/modules/app-module/entry";
import ScoreCard from "@/modules/app-module/score-card";

import LoadingSpinner from "@/components/global/loading-spinner.vue";

export default {
    name: "live-room",

    meta() {
        return {
            title: this.room?.title || "Room",
        };
    },

    props: {},

    components: {
        LoadingSpinner,
    },

    data() {
        return {
            timeout: 5000,
            loading: false,
            poll: null,
            room: {},
            scorecards: [],
            active: null,
            snackbar: false,
            snackbarText: "",
            snackbarColor: "",
            scribeConnection: null,
        };
    },

    computed: {
        competition() {
            return this.room?.data?.competition || {};
        },
        scribe() {
            return this.room?.data?.scribe || {};
        },
        judges() {
            return this.room?.data?.activeJudges || [];
        },
        isScribe() {
            let user = this.$app.user;

            if (user.persona == this.scribe._id) return true;

            return false;
        },
        isJudge() {
            let user = this.$app.user;

            return this.judges.find((judge) => judge.judge._id == user.persona);
        },
        round() {
            let scorecards = this.scorecards;

            scorecards = scorecards.filter((card) => card?.data?.contested || card?.data?.restarted);

            return scorecards.length + 1;
        },
        isReady() {
            let active = this.active;
            let user = this.$app.user;

            if (active?._id) {
                let scores = active?.posts?.score?.posts || [];

                let hasSubmitted = scores.some(
                    (score) => score?.managedAuthor == user.persona && score.data.round == this.round
                );

                console.log("SUBMITTED", hasSubmitted);

                if (!hasSubmitted) return true;
            }

            return false;
        },
    },

    methods: {
        /////////////////////////START SOCKET HANDELING////////////////////////////
        socketSetup() {
            //New
            this.socket = new WebSocket(
                `wss://smhb2elzv9.execute-api.ap-southeast-2.amazonaws.com/production?persona=${this.$app.user.persona}&room=${this.$route.params.room}`
            );
            this.socket.onopen = () => {
                console.log("Connected to WebSocket.");

                // Send a join command/message to the server
                const joinMessage = {
                    command: "join",
                    room: this.$route.params.room,
                    persona: this.$app.user.persona,
                };
                this.socket.send(JSON.stringify(joinMessage));
                this.startPing();
            };

            this.socket.onmessage = async (event) => {
                const data = JSON.parse(event.data);
                console.log("GOT ACTION", data.action);
                if (data.action == "refresh") {
                    this.run();
                } else if (data.action == "create") {
                    await this.getRoomScribe();
                }
            };

            this.socket.onclose = () => {
                console.log("Disconnected from WebSocket.");
                this.stopPing();
            };

            this.socket.onerror = (error) => {
                console.error("WebSocket error: ", error);
                this.stopPing();
            };
        },
        closeWebSocket() {
            if (this.socket) {
                this.socket.close();
                this.socket = null;
            }
        },
        startPing() {
            this.pingInterval = setInterval(() => {
                if (this.socket && this.socket.readyState === WebSocket.OPEN) {
                    let createMessage = {
                        command: "pingScribe",
                        name: this.$app.user.name,
                        persona: this.$app.user.persona,
                        scribeConnection: this.scribeConnection,
                    };
                    this.socket.send(JSON.stringify(createMessage));
                }
            }, 1500); // Ping interval in milliseconds
        },
        stopPing() {
            clearInterval(this.pingInterval);
            this.pingInterval = null;
        },
        //Sends a message out to all sockets to refresh
        socketRestart() {
            console.log("Sending socket refresh");
            const persona = this.$app.user.persona;
            console.log("SEDNING", persona);
            if (persona) {
                const message = {
                    command: "refresh",
                    room: this.$route.params.room,
                    persona: persona,
                };
                this.socket.send(JSON.stringify(message));
            } else {
                console.warn("NO PERSONA, FAILED TO SEND");
            }
        },
        async getRoomScribe() {
            //Get All detail Sheets
            const roomQuery = {
                definition: "socket",
                status: "active",
                "data.room": this.$route.params.room,
                // "data.activeOrgs": { $in: [currentOrg] }, //TO DO, make this work to increase performance
            };
            console.log(this.$route.params.room);
            const response = await this.$fluro.api.post("/content/_query", roomQuery).then(({ data }) => data);
            console.log(response);
            this.scribeConnection = response[0]?.data?.scribe;
        },

        /////////////////////////END SOCKET HANDELING////////////////////////////
        async run() {
            try {
                await this.$fluro.resetCache();

                this.room = await Room.get(this.$route.params.room).then(({ data }) => data);

                console.log("ROOM", this.room);

                if (this.room?.data?.active?._id) {
                    let activeId = this.room?.data?.active?._id;
                    this.active = await Entry.get(activeId, { params: { appendPosts: "all" } }).then(
                        ({ data }) => data
                    );

                    console.log("ACTIVE", this.active);

                    let query = {
                        _type: "article",
                        definition: "scoreCard",
                        "data.entry": `ObjectId(${this.active._id})`,
                    };

                    this.scorecards = await ScoreCard.query(query).then(({ data }) => data);
                    console.log("SCORE CARDS", this.scorecards);
                }

                let user = this.$app.user;

                if (this.isScribe) {
                    this.$router
                        .push({
                            name: "live-competitions-scribe",
                            params: {
                                competition: this.$route.params.competition,
                                category: this.$route.params.category,
                                room: this.$route.params.room,
                                scribe: user.persona,
                            },
                        })
                        .catch(() => {});
                } else if (this.isJudge && this.isReady) {
                    this.$router
                        .push({
                            name: "live-competitions-judge",
                            params: {
                                competition: this.$route.params.competition,
                                category: this.$route.params.category,
                                room: this.$route.params.room,
                                judge: user.persona,
                            },
                        })
                        .catch(() => {});
                } else {
                    // this.poll = setTimeout(this.run, this.timeout);
                }
            } catch (err) {
                console.log("ERROR");
            }
        },
    },

    async mounted() {
        await this.getRoomScribe();
        this.socketSetup();
        this.loading = true;
        await this.run();
        this.loading = false;

        if (this.$route?.query?.removed) {
            this.snackbar = true;
            this.snackbarText = "The scribe has removed you from the judging panel, your score was not submitted";
            this.snackbarColor = "error";
        }

        if (this.$route?.query?.submitted) {
            this.snackbar = true;
            this.snackbarText = "You have already submitted a score for this entry";
            this.snackbarColor = "warning";
        }
    },

    destroyed() {
        clearTimeout(this.poll);
        this.closeWebSocket();
    },
};
</script>

<style lang="scss"></style>
